body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

.app-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100vh;
}
